<template>
  <div class="detail-earnings">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">Detail</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content">
      <div class="item" v-if="show" @click="show = false">
        <van-image width="100%" :src="require('./img/img1.png')" />
        <div class="bg"></div>
      </div>

      <div class="item" v-else @click="show = true">
        <van-image width="100%" :src="require('./img/img2.png')" />
        <div class="bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.detail-earnings {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      overflow: initial;
    }

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .content {
    .item {
      position: relative;

      .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>